<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="isEdit">
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
      </h5>
      <div class="card-header-actions">
        <CButton color="dark" :to="this.prevRoute">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>

      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <CForm>
        <div class="order-header-container list-group-item shadow mb-3">
          <div>
            <p>
              <CLink :to="{ name: 'User', params: { id: order.user?.id || 0 } }">
                <CImg
                  :src="order.user?.image_icon || order.user?.gravatar"
                  class="c-avatar-img"
                  style="width: 36px; height: 36px"
                  placeholderColor="lightgray"
                />
                <span class="lead font-weight-bold text-dark ml-1">{{ order.roUseName }}</span>
              </CLink>
            </p>

            <dl>
              <dt v-if="order.roCompanyName" class="text-info"><CIcon name="cil-building" class="mr-1" />{{ order.roCompanyName }}</dt>
              <dt>
                <CIcon name="cil-at" class="mr-2" />
                <span v-if="showEmail">{{ order.user?.email }}</span>
                <span v-else>{{ maskedEmail }}</span>
                <CLink @click="showEmail = !showEmail" class="m-2">
                  <CIcon v-if="showEmail" name="cil-lock-unlocked" />
                  <CIcon v-else name="cil-lock-locked" />
                </CLink>
                <CLink @click="copyTextToClipboard(order.user?.email)"><CIcon name="cil-copy" /></CLink>
              </dt>

              <dt><CIcon name="cil-mobile" class="mr-1" />{{ order.roMobile }}</dt>
              <dt><CIcon name="cil-location-pin" class="mr-1" />{{ order.roPostal_code }} ({{ order.roDistance }}) {{ order.roCity }}</dt>
              <dd style="margin-left: 20px">{{ order.roAddress }}</dd>
            </dl>
          </div>

          <!-- Menute Delivery -->
          <div v-if="order.deliveryservice" class="order-ds flex-grow-1 thin-scrollbar mb-1 mb-lg-0">
            <p class="lead font-weight-bold">Menute Delivery Timeline</p>

            <!-- Not confirmed yet -->
            <span v-if="!(confirmationTime)" class="lead text-warning"><span class="flash-it">⚠</span>The order has not been confirmed yet!</span>

            <!-- Not yet assigned to courier -->
            <div v-else-if="!(order.delivery?.created_at_db)" class="d-flex flex-column flex-gap-2 lead">
              <div v-if="new Date(order.roAproxDeliveryDateToCourier) < new Date()">
                <span class="flash-it">⚠</span>
                <span class="text-danger">
                  The order has not yet been assigned to courier, please check!
                  It must be assigned as of <strong>{{ new Date(order.roAproxDeliveryDateToCourier).toLocaleString() }}</strong>.
                </span>
              </div>

              <span v-else class="lead text-info">
                🛈 The earliest time it will be assigned to the courier <strong>{{ new Date(order.roAproxDeliveryDateToCourier).toLocaleString() }}</strong>
              </span>

              <CButton class="align-self-center" color="info" shape="pill"
                @click="$store.state.transferOrRevokeOrderShow = true">
                <CIcon name="cil-transfer" class="mr-2" />Assign to courier
              </CButton>

              <div class="align-self-center d-flex align-items-center flex-gap-1">
                <small :class="{'text-warning': order.courierCannotBeAssigned, 'text-muted': !order.courierCannotBeAssigned}">Courier cannot be assigned:</small>
                <CSwitch labelOn="Yes" labelOff="No" color="warning" size="sm" shape="pill"
                  :checked.sync="order.courierCannotBeAssigned"
                  @update:checked="() => updateDeliveryServiceDetails(order)" />
              </div>
            </div>

            <!-- Delivery Timeline -->
            <div v-else class="timeline-container">
              <h5 class="order-courier-container text-center">
                <!-- COURIER -->
                <div v-if="order.delivery?.courier">
                  <div class="delivery-details">
                    Assigned to {{ order.delivery?.courier?.first_name }} (#{{ order.delivery?.courier?.id }})
                    <!-- Dispatcher -->
                    <span v-if="order.delivery?.dispatcher">by {{ order.delivery?.dispatcher.first_name }} (#{{ order.delivery?.dispatcher.id }})</span>
                  </div>
                  <div class="courier-details flex-row justify-content-center">
                    <a :href="`mailto:${order.delivery?.courier?.email}`">{{ order.delivery?.courier?.email }}</a>
                    &nbsp;📱 <a :href="`tel:${order.delivery?.courier?.mobile}`">{{ order.delivery?.courier?.mobile }}</a>
                  </div>
                </div>

                <!-- DISPATCHER -->
                <div v-else-if="order.delivery?.dispatcher && !order.delivery?.courier">
                  <div class="delivery-details">
                    Assigned to dispatcher {{ order.delivery?.dispatcher.first_name }} (#{{ order.delivery?.dispatcher.id }})
                  </div>
                  <div class="courier-details flex-row justify-content-center">
                    <a :href="`mailto:${order.delivery?.dispatcher.email}`">{{ order.delivery?.dispatcher.email }}</a>
                    &nbsp;📱 <a :href="`tel:${order.delivery?.dispatcher.mobile}`">{{ order.delivery?.dispatcher.mobile }}</a>
                  </div>
                  <div class="waiting-assignment my-2 text-center">
                    <span class="flash-it">⌛</span> Waiting for courier assignment by dispatcher...
                  </div>

                  <CButton class="align-self-center" color="info" shape="pill"
                    @click="$store.state.transferOrRevokeOrderShow = true">
                    <CIcon name="cil-transfer" class="mr-2" />Transfer or Revoke
                  </CButton>
                </div>
              </h5>

              <!-- Show timeline if courier is present -->
              <div v-if="order.delivery?.courier" class="timeline mb-5">
                <div class="event has-sub" :class="{ 'done': order.delivery?.confirmed }">
                  <div class="circle">
                    <h4>Assigned</h4>
                    <p>{{ new Date(order.delivery?.created_at_db).toLocaleString() }}</p>

                    <CLink v-if="order.delivery?.arrived_at_restaurant === null"
                      class="align-self-baseline"
                      v-c-tooltip="{ content: 'Transfer or revoke order', placement: 'right' }"
                      @click="$store.state.transferOrRevokeOrderShow = true"
                    >
                      <CIcon name="cil-transfer" />
                    </CLink>

                    <div class="sub-event" :class="{ 'done': order.delivery?.confirmed }">
                      <h6>Confirm</h6>
                      <p v-if="order.delivery?.confirmed" class="text-muted">
                        {{ humanizeDateDifference(order.delivery?.created_at_db, order.delivery?.confirmed) }}
                      </p>
                      <p v-else><DeliveryStep step="confirmed" @confirm="handleDeliveryStep" /></p>
                    </div>
                  </div>
                  <span class="point"><span></span></span>
                </div>

                <div class="event" :class="{ 'done': order.delivery?.arrived_at_restaurant }">
                  <div class="circle">
                    <h4>Arrived at the restaurant</h4>
                    <p v-if="order.delivery?.arrived_at_restaurant" class="text-muted">
                      {{ humanizeDateDifference(order.delivery?.created_at_db, order.delivery?.arrived_at_restaurant) }}
                      <br />
                      ({{ new Date(order.delivery?.arrived_at_restaurant).toLocaleString() }})
                    </p>
                    <DeliveryStep v-else step="arrived-at-restaurant" @confirm="handleDeliveryStep" />
                  </div>
                  <span class="point"><span v-if="order.delivery?.arrived_at_restaurant"></span></span>
                </div>

                <div class="event" :class="{ 'done': order.delivery?.picked_up }">
                  <div class="circle">
                    <h4>Picked up
                      <span class="text-dark" v-if="order.courierPickupCode">({{ order.courierPickupCode }})</span>
                    </h4>
                    <p v-if="order.delivery?.picked_up" class="text-muted">
                      {{ humanizeDateDifference(order.delivery?.arrived_at_restaurant, order.delivery?.picked_up) }}
                      <br />
                      ({{ new Date(order.delivery?.picked_up).toLocaleString() }})
                    </p>
                    <DeliveryStep v-else step="picked-up" @confirm="handleDeliveryStep" />
                  </div>
                  <span class="point"><span v-if="order.delivery?.picked_up"></span></span>
                </div>

                <div class="event" :class="{ 'done': order.delivery?.arrived_at_customer }">
                  <div class="circle">
                    <h4>Arrived at the customer</h4>
                    <p v-if="order.delivery?.arrived_at_customer" class="text-muted">
                      {{ humanizeDateDifference(order.delivery?.picked_up, order.delivery?.arrived_at_customer) }}
                      <br />
                      ({{ new Date(order.delivery?.arrived_at_customer).toLocaleString() }})
                    </p>
                    <DeliveryStep v-else step="arrived-at-customer" @confirm="handleDeliveryStep" />
                  </div>
                  <span class="point"><span v-if="order.delivery?.arrived_at_customer"></span></span>
                </div>

                <div class="event" :class="{ 'done': order.delivery?.delivered }">
                  <div class="circle">
                    <h4>Delivered</h4>
                    <p v-if="order.delivery?.delivered" class="text-muted">
                      {{ humanizeDateDifference(order.delivery?.arrived_at_customer, order.delivery?.delivered) }}
                      <br />
                      ({{ new Date(order.delivery?.delivered).toLocaleString() }})
                    </p>
                    <DeliveryStep v-else step="delivered" @confirm="handleDeliveryStep" />
                  </div>
                  <span class="point"><span v-if="order.delivery?.delivered"></span></span>
                </div>
                <!--
                <div class="event has-sub" :class="{ 'done': order.delivery?.picked_up }">
                  <div class="circle">
                    <h4>Picked up</h4>
                    <p>{{ moment(order.delivery?.picked_up).format("HH:mm") }}</p>
                    <div class="sub-event">
                      <h6>Break</h6>
                      <p>20:12</p>
                    </div>
                  </div>
                  <span class="point"><span></span></span>
                </div>
                -->
              </div>
            </div>
          </div>

          <div>
            <div class="d-flex justify-content-end mb-3">
              <CButtonToolbar aria-label="Order Tracking Link">
                <em class="text-muted align-self-center">Tracking Link:</em>
                <CButtonGroup size="sm" class="mx-1">
                  <CButton
                    color="light"
                    @click="copyTextToClipboard(orderTrackingLink)"
                  >
                    <CIcon name="cil-copy"/>
                  </CButton>

                  <CButton
                    color="light"
                    :href="orderTrackingLink"
                    target="_blank"
                  >
                    <CIcon name="cil-external-link"/>
                  </CButton>
                </CButtonGroup>
              </CButtonToolbar>

              <CToaster position="bottom-right" :autohide="3000" class="mb-5 mr-4">
                <CToast v-for="toast in toasts" :key="'toast' + toast" :show="true" color="success">&#10003; Copied!</CToast>
              </CToaster>
            </div>

            <CAlert show color="warning" class="p-2" v-if="order.roUnderControl == 1">
              👁️ Under Control
            </CAlert>

            <CAlert show color="success" class="p-2" v-if="order.email_show == 1">
              <CIcon :height="24" name="cil-envelope-open" class="mr-2" />
              <span>Email checked at {{ order.email_show_date }}</span>
            </CAlert>
          </div>
        </div>

        <CTabs class="tab-menu" :active-tab.sync="activeTab" variant="pills" :vertical="{ navs: 'col-xl-2 col-md-3', content: 'col-xl-10 col-md-9' }">
          <!-- Details -->
          <CTab active>
            <template slot="title">
              <CIcon name="cil-notes"/> Details
            </template>

            <CRow class="mt-2 mt-md-0">
              <CCol xl="6" col="12">
                <!-- Details -->
                <div>
                  <p class="lead font-weight-bold">Details</p>

                  <ul class="pl-4">
                    <li class="text-danger">Gevraagde <span v-html="order.roPickup ? '<b>afhaal</b>' : 'lever'"></span>tijd: {{ order.roDesiredDelivireyTime }}</li>
                    <li>Subtotaal: {{ order.roPrice | toCurrency }}</li>
                    <li>Bezorgkosten: {{ order.roDeliveryPrice | toCurrency }}</li>
                    <li>Totaal restaurant: {{ (order.roDeliveryPrice + order.roPrice) | toCurrency }}</li>
                    <li>Handelingskosten: {{ order.roHandlingfee | toCurrency }} </li>
                  </ul>

                  <ul class="pl-4">
                    <li>
                      Betalingswijze: {{ order.roPaysWith }}
                      {{
                        order.roPaysCache > 0
                          ? " Wilt betalen met " + order.roPaysCache
                          : ""
                      }}
                      {{
                        order.roPaysCache < 0
                          ? order.roPymntVfd == 1
                            ? " Betaling ontvangen"
                            : " Betaling NOG NIET ontvangen"
                          : ""
                      }}
                    </li>
                  </ul>
                </div>
              </CCol>

              <CCol xl="6" col="12">

                <!-- Restaurant -->
                <div class="mt-4 mt-xl-0">
                  <p class="lead font-weight-bold">Restaurant</p>
                  <div v-if="order.restaurant" class="d-flex align-items-center">
                    <div class="c-avatar mr-2">
                      <CImg
                        :src="order.restaurant.restaurant_logo_path"
                        class="c-avatar-img"
                        style="width: 36px; height: 36px"
                        placeholderColor="lightgray"
                      />
                    </div>
                    <div>
                      <CLink :to="{name: 'View Restaurant', params: { id: order.restaurant.id }}" class="text-muted">{{ order.restaurant.restaurant_name }}</CLink>
                      <div>
                        <strong>{{order.restaurant.restaurant_city }}</strong> <small>{{order.restaurant.restaurant_postcode }}</small>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Notes -->
                <div v-if="order._details_html" class="mt-4">
                  <p class="lead font-weight-bold">Notes</p>

                  <CAlert show color="info" v-if="order._details_html.notes">
                    <CIcon :height="24" name="cil-info" class="mr-2" />
                    <span>{{ order._details_html.notes }}</span>
                  </CAlert>

                  <ul class="pl-4">
                    <li v-for="detail in order._details_html.details" :key="detail">
                      {{ detail }}
                    </li>
                  </ul>
                </div>
              </CCol>
            </CRow>

            <!-- Messages -->
            <section class="mt-4 list-group-item shadow">
              <p class="lead font-weight-bold">Messages</p>
              <Messages v-if="order.messages?.length" :messages="order.messages" />
              <span v-else>No messages.</span>
            </section>
          </CTab>

          <!-- Internal Notes & Confirm -->
          <CTab>
            <template slot="title">
              <CIcon name="cil-check-alt"/> Confirm
            </template>

            <section class="mt-2 mt-md-0">
              <p class="lead font-weight-bold">Internal Notes</p>
              <CAlert show color="success" v-if="internNotes">
                <ul class="pl-4 mb-0">
                  <li v-for="note in internNotes" :key="note">
                    {{ note }}
                  </li>
                </ul>
              </CAlert>

              <div class="d-flex flex-column flex-sm-row">
                <CTextarea
                  class="flex-grow-1"
                  label="Internal Note Only"
                  placeholder="Please input so much information"
                  :lazy="false"
                  :value.sync="$v.form.internNote.$model"
                  rows="3"
                  :isValid="checkIfValid('internNote')"
                  invalidFeedback="Please input so much information"
                />

                <CButton
                  class="h-100 mt-175 ml-050"
                  size="sm"
                  color="success"
                  variant="outline"
                  shape="pill"
                  :disabled="submitted"
                  @click="confirmDialog('addNote')"
                >
                  <CIcon name="cil-plus" />Add Note
                </CButton>
              </div>
            </section>

            <section class="mt-4 list-group-item shadow">
              <p class="lead font-weight-bold">Confirm</p>

              <div class="d-flex flex-column flex-sm-row justify-content-between flex-gap-3">
                <CSelect v-if="(order.roDesiredDelivireyTime == 'asap') && !confirmationTime"
                  class=""
                  label="Delivery Time"
                  description="(Minute)"
                  :options="[15, 30, 45, 60]"
                  custom
                  :value.sync="$v.form.deliveryTime.$model"
                />

                <!-- Time Picker -->
                <div class="form-group" v-else>
                  <label style="display: block;">Approx Delivery Time</label>

                  <!-- fixed-dropdown-button -->
                  <vue-timepicker
                    format="HH:mm"
                    :minute-interval="5"
                    close-on-complete
                    hide-clear-button
                    auto-scroll
                    v-model="$v.form.approxDeliveryTime.$model">
                  </vue-timepicker>
                </div>

                <div class="form-group flex-grow-1" v-if="order.roAproxDeliveryTimeToCourier">
                  <label style="display: block;">Delivery Time To Courier</label>

                  <span class="badge badge-primary p-2" style="font-size: 1.25rem; letter-spacing: 0.2rem;">
                    {{ order.roAproxDeliveryTimeToCourier }}
                  </span>
                </div>
              </div>

              <div class="form-group mt-3">
                <CInputCheckbox
                  label="Send SMS"
                  description="Default confirmation message will be sent to customer"
                  custom
                  :lazy="false"
                  :checked.sync="$v.form.sendSms.$model"
                />
              </div>

              <CButton
                color="info"
                :disabled="submitted || !!(order.delivery)"
                @click="confirmDialog('confirm')"
              >
                <CIcon name="cil-check-alt" /> Confirm
              </CButton>
            </section>
          </CTab>

          <!-- Payments -->
          <CTab>
            <template slot="title">
              <CIcon name="cil-euro"/> Payments
            </template>

            <CRow>
              <CCol xl="6" col="12">
                <!-- Pay Attempts -->
                <section class="mt-2 mt-md-0" v-if="order.online_payments && order.online_payments.length > 0">
                  <p class="lead font-weight-bold">Online Pay Attempts</p>

                  <ul class="pl-4">
                    <li v-for="payment in order.online_payments" :key="payment.id">
                      <strong>{{ payment.amount | toCurrency }}</strong> |
                      <strong>{{ payment.status | ucfirst }}</strong>
                      {{ payment.provider }} <small>(#{{ payment.payment_id }})</small>
                    </li>

                    <!-- Refunded Amount -->
                    <li v-if="order.roRefundedVal" class="alert-warning mt-3 p-2">
                      <strong>Refunded amount: {{ order.roRefundedVal | toCurrency }}</strong>
                    </li>
                  </ul>

                  <CAlert show color="danger" v-if="order.online_payments.length > 0" style="display: flex;">
                    <CIcon :height="36" name="cil-bell-exclamation" class="mr-2" />
                    Paid, verified of succeeded is betaald !! Als er niets staat is het niet betaald enkel poging tot betalen
                  </CAlert>
                </section>

                <!-- Vouchers -->
                <section class="mt-4" v-if="!!(order.coupons?.length)">
                  <p class="lead font-weight-bold">Vouchers:</p>

                  <ul class="pl-4">
                    <li v-for="coupon in order.coupons" :key="coupon.code">
                      <strong>{{ coupon.discount_amount | toCurrency }}</strong> |
                      <span>{{ coupon.code }}</span>
                      <small v-if="coupon.description"> ({{ coupon.description }})</small>
                    </li>
                  </ul>
                </section>

                <!-- MenutePay -->
                <section class="mt-4" v-if="!!(order.menutepay_transactions?.length)">
                  <p class="lead font-weight-bold">MenutePay</p>

                  <ul class="pl-4">
                    <li v-for="mp in order.menutepay_transactions" :key="mp.id">
                      <strong>{{ parseFloat(mp.value) | toCurrency }}</strong>
                      <small v-if="mp.budget_group?.intern_name"> ({{ mp.budget_group.intern_name }})</small>
                    </li>
                  </ul>
                </section>
              </CCol>

              <!-- Payment Breakdown Chart -->
              <CCol xl="6" col="12">
                <section class="mt-4 mt-xl-0 d-flex flex-column">
                  <p class="lead font-weight-bold">Payment Breakdown Chart</p>
                  <CChartPie
                    :datasets="allPayments"
                    :labels="['MenutePay', 'Voucher', 'Online Payment']"
                  />
                </section>
              </CCol>
            </CRow>
          </CTab>
        </CTabs>
      </CForm>
    </CCardBody>

    <CCardFooter class="sticky d-flex justify-content-between">
      <div class="vertical-center flex-gap-2">
        <CButton size="sm" color="dark" variant="ghost" shape="pill" v-c-tooltip="{ content: 'Order Activities' }"
          @click="() => $store.commit('set', ['orderActivityLogsAsideShow', true])">
          <CIcon name="cil-fingerprint" size="xl" />
        </CButton>

        <CButton color="warning" :disabled="order.roUnderControl == 1 || submitted" @click="confirmDialog('takeControl')">
          👁️ Take Control
        </CButton>
      </div>

      <CDropdown
        color="danger"
        :disabled="order.roDeleted == 1 || submitted"
      >
        <template #toggler-content>
          <CIcon name="cil-trash" /> Delete
        </template>
        <CDropdownItem disabled>👇 Please choose the reason:</CDropdownItem>
        <CDropdownDivider/>
        <CDropdownItem @click="confirmDialog('deleteOrder', 0)">Test Order</CDropdownItem>
        <CDropdownItem @click="confirmDialog('deleteOrder', -1)">Fake Order</CDropdownItem>
        <CDropdownItem @click="confirmDialog('deleteOrder', 1)">Canceled by Customer</CDropdownItem>
        <CDropdownItem @click="confirmDialog('deleteOrder', 2)">Restaurant Closed</CDropdownItem>
      </CDropdown>
    </CCardFooter>

    <CElementCover :opacity="0.8" v-show="submitted"/>

    <TransferOrRevokeOrder
      :courierId="order.delivery?.courier?.id"
      :dispatcherId="order.delivery?.dispatcher?.id"
      :order="$globalFunc.extractKeys(order, ['id', 'roDesiredDelivireyTime', 'roAproxDeliveryDateToCourier', 'roAddress', 'roCity', 'roPostal_code', 'roCountryId'])"
      :restaurant="$globalFunc.extractKeys(order?.restaurant, ['id', 'restaurant_postcode'])"
      @transferred="getForm(itemId)"
    />

    <OrderActivityLogs :order="order" />
  </CCard>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength } from "vuelidate/lib/validators"
import { CChartPie } from '@coreui/vue-chartjs'
import Messages from './Messages.vue'
import TransferOrRevokeOrder from "@/components/Courier/TransferOrRevokeOrder";
import DeliveryStep from "@/components/Courier/DeliveryStep";
import OrderActivityLogs from "./OrderActivityLogs";

// https://phoenixwong.github.io/vue2-timepicker/
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'Order',
  components: {
    VueTimepicker,
    CChartPie,
    Messages,
    TransferOrRevokeOrder,
    DeliveryStep,
    OrderActivityLogs
  },
  data() {
    return {
      order: {},
      form: {},
      confirmationTime: null,

      activeTab: 0,
      isEdit: false,
      submitted: false,

      itemName: '',

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      prevRoute: null,
      internNotes: [],
      showEmail: false,
      toasts: 0
    }
  },

  // Get previous page
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  async created () {
    if(this.itemId) {
      await this.getForm(this.itemId)
      this.isEdit = true
    }
    else
      this.getForm()
  },

  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    itemId () { return this.$route.params.id },
    orderTrackingLink () {
      const MAIN_SITE = process.env.VUE_APP_MAIN_SITE.replace('{COUNTRY_EXTENSION}', 'be');
      return `${MAIN_SITE}/foodtracker?tracker=${this.order.rotrackingid}`;
    },
    maskedEmail() {
      return this.order.user?.email.replace(/(?<=^.)[^@]*|(?<=@.).*(?=\.[^.]+$)/g, '***');
    },
    allPayments () {
      return [
        {
          backgroundColor: [
            '#9b0a0a',
            '#41B883',
            '#00D8FF',
          ],
          data: [
            this.order.menutepay_transactions?.reduce(function (a, b) { return a + parseFloat(b['value']); }, 0),
            this.order.coupons?.reduce(function (a, b) { return a + b['discount_amount']; }, 0),
            this.order.online_payments
              ?.filter(op => ['succeeded', 'completed', 'paid'].includes(op.status?.toLowerCase()))
              .reduce(function (a, b) { return a + b['amount']; }, 0)
          ]
        }
      ]
    }
  },

  mixins: [validationMixin],

  validations: {
    form: {
      // Name: { required, minLength: minLength(2) },
      internNote: { required, minLength: minLength(3) },
      deliveryTime: {},
      sendSms: {},
      approxDeliveryTime: {},
    }
  },

  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },

    confirmDialog (func, param) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ You will not be able to recover this! Do you really want to continue?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this[func](param);
          }
        },
      });
    },

    addNote () {
      if (this.isValid) {
        this.submitted = true

        this.$axios.put(this.$backend.ORDERS.ADD_NOTE.replace("{id}", this.itemId),
          { internNote: this.form.internNote }
        ).then((response) => {
            // this.itemName = response.data.roStrId;
            // this.successAlertMessage = {itemName: this.itemName, message: 'updated.'}
            // this.dismissSuccessAlert = this.dismissSecs
            // this.internNotes.push(this.form.internNote);

            // Internal Notes
            if (typeof response.data !== 'undefined') {
              this.internNotes = JSON.parse(response.data);
            }

            this.showErrorAlert = false
          })
          .catch((error)=>{
            this.errorAlertMessage = { 'Error': error.response.data.message }
            this.showErrorAlert = true
          })
          .finally(() => {
            this.form.internNote = "";
            this.reset();
          });
      }
      else
        this.validate()
    },

    confirm () {
      this.submitted = true

      this.$axios.put(this.$backend.ORDERS.CONFIRM.replace("{id}", this.itemId),
        {
          internNote: this.form.internNote,
          sendSms: this.form.sendSms,
          deliveryTime: (this.order.roDesiredDelivireyTime == 'asap') && !this.confirmationTime
            ? this.form.deliveryTime
            : this.form.approxDeliveryTime,
        }
      ).then((response) => {
          this.confirmationTime = response.data.confirmationTime;
          this.form.approxDeliveryTime = response.data.approxDeliveryTime.padStart(5, "0");
          this.order.roAproxDeliveryTimeToCourier = response.data.approxDeliveryTimeToCourier;
          this.order.roAproxDeliveryDateToCourier = response.data.approxDeliveryDateToCourier;

          // Internal Notes
          if (typeof response.data.internNotes !== 'undefined') {
            this.internNotes = JSON.parse(response.data.internNotes);
          }

          this.showErrorAlert = false
        })
        .catch((error)=>{
          this.errorAlertMessage = { 'Error': error.response.data.message }
          this.showErrorAlert = true
        })
        .finally(() => {
          window.scrollTo(0, 0);
          this.form.internNote = "";
          this.form.sendSms = false;
          this.form.deliveryTime = 30;
          this.reset();
        });
    },

    takeControl () {
      this.submitted = true

      this.$axios.put(this.$backend.ORDERS.TAKE_CONTROL.replace("{id}", this.itemId))
        .then((response) => {

          this.order.roUnderControl = 1;

          // Internal Notes
          if (typeof response.data !== 'undefined') {
            this.internNotes = JSON.parse(response.data);
          }

          this.showErrorAlert = false
        })
        .catch((error)=>{
          this.errorAlertMessage = { 'Error': error.response.data.message }
          this.showErrorAlert = true
        })
        .finally(() => {
          this.submitted = false
          // this.form.internNote = "";
          // this.form.sendSms = false;
          // this.form.deliveryTime = 30;
          // this.reset();
        });
    },

    deleteOrder (reason) {
      this.submitted = true

      this.$axios.put(this.$backend.ORDERS.DELETE.replace("{id}", this.itemId),
        { internNote: this.form.internNote, sendSms: this.form.sendSms, reason: reason }
      ).then((response) => {

          this.order.roDeleted = true;

          // Internal Notes
          if (typeof response.data !== 'undefined') {
            this.internNotes = JSON.parse(response.data);
          }

          this.showErrorAlert = false
        })
        .catch((error)=>{
          this.errorAlertMessage = { 'Error': error.response.data.message }
          this.showErrorAlert = true
        })
        .finally(() => {
          this.form.internNote = "";
          this.form.sendSms = false;
          this.reset();
        });
    },


    validate () {
      this.$v.$touch()
    },

    reset () {
      // this.form = this.getForm();
      this.submitted = false
      this.$v.$reset()
    },

    async getForm (id = null) {
      this.form = {
        internNote: "",
        sendSms: false,
        deliveryTime: 30,
        approxDeliveryTime: '00:00'
      }

      if(id) {
        this.submitted = true;
        await this.$axios.get(this.$backend.ORDERS.SHOW.replace("{id}", id))
          .then(({data}) => {
            this.order = data;
            this.itemName = data.roStrId;
            this.confirmationTime = data.roConfirmationTime;

            // Internal Notes
            if (typeof data.roInternNotes !== 'undefined') {
              this.internNotes = JSON.parse(data.roInternNotes);
            }

            // Approx Delivery Time
            if (data.roAproxDeliveryTime) {
              this.form.approxDeliveryTime = data.roAproxDeliveryTime.padStart(5, "0");
              // ....padStart(5, "0")
              // 7:40  -> 07:40
              // 07:40 -> 07:40
            } else if (data.roDesiredDelivireyTime != 'asap') {
              this.form.approxDeliveryTime = data.roDesiredDelivireyTime.padStart(5, "0");
            }
          })
          .catch(() => {
            this.$router.push(this.prevRoute)
          })
          .finally(() => {
            this.submitted = false;
          })
      }
    },

    copyTextToClipboard(text) {
      this.$globalFunc.copyTextToClipboard(text);
      this.toasts++;
    },

    humanizeDateDifference(start, end) {
      if (!start || !end) {
        return "";
      }

      var startDate = this.moment(start);
      var endDate = this.moment(end);

      var differenceInMinutes = endDate.diff(startDate, 'minutes');
      var humanizedDifference = this.moment.duration(differenceInMinutes, 'minutes').humanize(true);

      return humanizedDifference;
    },

    updateDeliveryServiceDetails(item) {
      this.$axios.post(
        this.$backend.ORDERS.UPDATE_DELIVERY_SERVICE.replace("{id}", item.id),
        { details: { courier_cannot_be_assigned: item.courierCannotBeAssigned } }
      )
        .catch(() => {
          item.courierCannotBeAssigned = !item.courierCannotBeAssigned;
        });
    },

    async handleDeliveryStep(step) {
      this.submitted = true;

      try {
        await this.$axios.put(this.$backend.COURIER.ORDERS.UPDATE_ACTIONS, {
          order_id: this.order?.id,
          action: step,
          courier: this.order?.delivery?.courier?.id,
        });

        await this.getForm(this.itemId);
      } catch (error) {
        let errorMessage = 'An error occurred';
        if (typeof error.response?.data === 'object') {
          errorMessage = Object.values(error.response.data).join(', ');
        } else if (error.message) {
          errorMessage = error.message;
        }
        this.$toast.error(errorMessage);
      } finally {
        this.submitted = false;
      }
    }
  }
}
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.mt-175{
  margin-top: 0;
}

@media (min-width: 576px) {
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .mt-175{
    margin-top: 1.75rem;
  }
  .ml-050 {
    margin-left: 0.5rem !important;
  }
}

.order-header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.order-header-container .order-ds {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
}
@media (max-width: 1200px) {
  .order-header-container .order-ds {
    order: 1;
    width: 100%;
  }
}
</style>

<style>
.vue__time-picker input.display-time {
  width: 6em;
  height: 1.7em;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  color: #768192;
  font-size: 1.5em;
  font-family: monospace;
  padding: 0 0.5em;
  text-align: center;
  letter-spacing: 0.2em;
}
.vue__time-picker-dropdown, .vue__time-picker .dropdown {
  top: calc(2.4em + 2px);
}
</style>

<style lang="scss">
@import "src/assets/scss/timeline.scss";
</style>
