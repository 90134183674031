<template>
  <CDataTable class="order-messages" hover :items="messages" :fields="fields" clickable-rows
    @row-clicked="rowClicked">

    <!-- Channel -->
    <template #channel="{ item }">
      <td>
        <img :src="`/img/icons/${item.channel}.svg`" height="24" />
      </td>
    </template>

    <!-- Date (created_at) -->
    <template #created_at="{ item }">
      <td>{{ moment(item.created_at).format('HH:mm:ss, DD MMMM YYYY') }}</td>
    </template>

    <!-- Show Details -->
    <template #show_details="{ item, index }">
      <td class="text-right">
        <CButton size="sm" color="link" @click="toggleDetails(item, index)">
          <CIcon v-if="Boolean(item._toggled)" name="cil-chevron-circle-up-alt" />
          <CIcon v-else name="cil-chevron-circle-down-alt" />
        </CButton>
      </td>
    </template>

    <!-- Details -->
    <template #details="{ item, index }">
      <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
        <div class="d-flex flex-column flex-gap-2 p-2">
          <div><strong>Receiver:</strong> {{ item.receiver }}</div>
          <div><strong>App ID:</strong> {{ item.app_id }}</div>
          <div v-if="item.content">
            <strong>Content</strong>
            <json-viewer :value="item.content" :expand-depth=2></json-viewer>
          </div>
          <div v-if="item.response">
            <strong>Response</strong>
            <json-viewer :value="item.response" :expand-depth=2></json-viewer>
          </div>
        </div>
      </CCollapse>
    </template>
  </CDataTable>
</template>

<script>
import JsonViewer from 'vue-json-viewer'

export default {
  name: "Messages",
  components: {
    JsonViewer
  },
  props: {
    messages: { default: () => [], type: Array },
  },
  data() {
    return {
      fields: [
        { key: "channel", label:"", _style: "width: 48px; max-width: 48px;" },
        { key: "created_at", label:"" },
        { key: "show_details", label:"", _style: "width: 48px; max-width: 48px" },
      ],

      collapseDuration: 0,
    };
  },

  methods: {
    rowClicked(item, index, column, e) {
      // INPUT, LABEL, BUTTON vb... dışında herhangi bir satır nesnesine tıklanırsa;
      if (!['INPUT', 'LABEL', 'BUTTON', 'svg', 'path', 'A', 'IMG', 'SPAN', 'DIV'].includes(e.target.tagName)) {
        this.toggleDetails(item)
      }
    },
    toggleDetails(item) {
      this.$set(item, "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
  },
};
</script>

<style>
.order-messages thead th {
  border: 0;
  padding: 0;
}
.order-messages td {
  padding: 0.3rem 0.5rem;
  vertical-align: middle;
}
.jv-container .jv-code {
  padding: 10px 10px 0px;
}
</style>
