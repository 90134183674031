<template>
  <CSidebar
    aside
    :show="$store.state.orderActivityLogsAsideShow"
    @update:show="(val) => $store.commit('set', ['orderActivityLogsAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'orderActivityLogsAsideShow')" />

    <!-- Error Alert -->
    <CListGroup class="list-group-accent mt-5" v-show='showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase">
        Error
      </CListGroupItem>

      <CListGroupItem href="#" class="list-group-item-accent-danger list-group-item-divider">
        <div class="c-avatar float-left mr-2">
          <CIcon name="cil-bug" size="xl" class="c-avatar-img text-danger"/>
        </div>
        <div>{{ errorAlertMessage }}</div>
      </CListGroupItem>
    </CListGroup>
    <!-- / Error Alert -->

    <CListGroup class="scrollable list-group-accent mt-5" v-show='!showErrorAlert'>

      <CListGroupItem class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        Activity Logs
      </CListGroupItem>

      <CListGroupItem v-if="!(loading || logs.length)" class="list-group-item-accent-danger">
        <div class="text-muted">There are no activities to display.</div>
      </CListGroupItem>

      <template v-for="activity in logs">
        <CListGroupItem class="list-group-item-divider list-group-item-accent-info">
          <div>{{ activity?.description }}</div>
          <div class="small text-muted mt-1">
            <CIcon name="cil-calendar" class="mr-2" />
            {{ moment(activity.created_at).calendar() }}
          </div>
        </CListGroupItem>
      </template>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading || submitted"/>
  </CSidebar>
</template>

<script>
export default {
  name: "OrderActivityLogs",
  props: {
    order: {
      type: Object,
      default: null
    },
  },
  components: {
  },
  data() {
    return {
      loading: false,
      submitted: false,
      logs: [],

      errorAlertMessage: '',
      showErrorAlert: false,
    };
  },
  watch: {
    async reloadParams() {
      this.$store.state.orderActivityLogsAsideShow && await this.getOrderActivityLogs();
    },
  },
  computed: {
    reloadParams() {
      return [this.$store.state.orderActivityLogsAsideShow];
    },
  },

  methods: {
    async getOrderActivityLogs() {
      this.logs = [];
      this.loading = true;
      await this.$axios.get(this.$backend.ACTIVITIES.GET_BY_PROPERTY, {
          params: {
            'order_id': this.order.id
          }
        })
        .then((response) => {
          this.logs = response.data.map((el) => {
            return {
              description: el.description,
              created_at: el.created_at,
            };
          })
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data.message
          this.showErrorAlert = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}
</style>
