<template>
  <div v-if="pendingConfirm" class="d-flex align-items-center flex-gap-2">
    <span class="text-danger">Do you confirm?</span>
    <CLink @click="confirmStep">
      <CIcon name="cil-check-alt" />
    </CLink>
    <CLink @click="cancelConfirm">
      <CIcon name="cil-x" />
    </CLink>
  </div>
  <CLink v-else @click="startConfirmation">
    <CIcon name="cil-arrow-thick-right" />
  </CLink>
</template>

<script>
export default {
  props: {
    step: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pendingConfirm: false,
      timeoutId: null
    };
  },
  methods: {
    confirmStep() {
      this.$emit('confirm', this.step);
      this.resetConfirmation();
    },
    cancelConfirm() {
      this.resetConfirmation();
    },
    startConfirmation() {
      this.pendingConfirm = true;
      this.resetTimeout();
    },
    resetConfirmation() {
      this.pendingConfirm = false;
      this.clearTimeout();
    },
    resetTimeout() {
      this.clearTimeout();
      this.timeoutId = setTimeout(() => {
        this.pendingConfirm = false;
      }, 2000);
    },
    clearTimeout() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    }
  },
  beforeDestroy() {
    this.clearTimeout();
  }
};
</script>

<style scoped>
.flex-gap-1 {
  gap: 1rem;
}
</style>
